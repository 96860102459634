/* misc */
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex {
  flex: 1;
}
/* end the misc */
body {
  background-image: url("space10.jpg");
  background-size: 100px;
  background-repeat: repeat;
}

.i-wrap-the-world {
  margin-top: 10px;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.the-logo {
  width: 250px;
  margin-bottom: 10px;
}

.monster-width {
  width: 100%;
}

.i-will-be-your-header {
}

.wtf-am-i-doing-with-my-life {
  height: 40px;
}

.the-title {
  color: black;
  background-color: #bfff00;
  margin-top: auto;
  margin-left: 20px;
}

.i-will-be-your-links {
  margin-top: 20px;
  padding: 20px;
  border-top: 2px dotted gray;
  border-bottom: 2px dotted grey;
}

a, .lunk {
  font-size: 1.1rem;
  color: #16A085;
}

.a-header-lunk {
  margin-left: 15px;
  text-transform: uppercase;
}

.i-am-your-events {
  margin-top: 20px;
}

.make-me-taller-daddy {
  line-height: 1.666;
}

.squish-my-burrito {
  margin-left: 15px;
  margin-right: 15px;
}

.give-it-a-bit-more {
  margin-bottom: 10px;
}

.label-loving {
  display: block;
}

.contact-schmontact {
  display: block;
  width: 100%;
}

.tomato-vampires {
  color: red;
}

.hide-a-bit {
  color: #222;
}

.i-like-rice {
  margin-top: 10px;
  text-align: center;
}

.like-a-rolling-stone {
  margin-right: 10px;
  font-weight: normal;
  color: #EEE;
  font-size: 14px;
}

.to-rsvp {
  margin-left: 15px;
}

.make-me-like-a-button {
  background: #bfff00;
  color: #000;
  padding: 5px 10px 5px 10px;
  border-radius: 2px;
}

.stinky-footer {
  margin-bottom: 15px;
}

.foot-cheese {
  color: #00ffff;
  margin-left: 20px;
}
